document.addEventListener('DOMContentLoaded', function () {
    const menuItems = document.querySelectorAll('.menu-item-has-children');

    menuItems.forEach(item => {
        const link = item.querySelector('a');

        link.addEventListener('click', function (e) {
            e.preventDefault(); // Prevent the default link behavior

            // First, close any other open sub-menus
            menuItems.forEach(i => {
                if (i !== item && i.classList.contains('open')) {
                    i.classList.add('closing');
                    i.classList.remove('open');

                    // Listen for the end of the closing transition
                    const subMenu = i.querySelector('.sub-menu');
                    subMenu.addEventListener('transitionend', function onTransitionEnd() {
                        i.classList.remove('closing');
                        subMenu.removeEventListener('transitionend', onTransitionEnd);
                    }, { once: true });
                }
            });

            // Delay opening the clicked sub-menu until any closing animations are done
            setTimeout(() => {
                item.classList.toggle('open');
            }, 50); // Match this timeout to the duration of your transition (0.5s in this case)
        });
    });
});
