document.addEventListener('DOMContentLoaded', function () {
    const menuToggle = document.querySelector('.menu-toggle');
    const mobileMenu = document.querySelector('.mobile-menu');
    const menuToggleIconOff = menuToggle.querySelector('i.off');
    const menuToggleIconOn = menuToggle.querySelector('i.on');
    const closeMenu = document.querySelector('.close-menu');
    const closeMenuIconOff = closeMenu.querySelector('i.off');
    const closeMenuIconOn = closeMenu.querySelector('i.on');
    const telephoneIcon = document.querySelector('.site-header-inner .telephone-number a');

    menuToggle.addEventListener('click', function () {
        if (menuToggle.getAttribute('aria-expanded') === 'false') {
            menuToggle.setAttribute('aria-expanded', 'true');
            mobileMenu.style.transition = 'margin-left 300ms';
            mobileMenu.style.marginLeft = '0';
            console.log('open')
        } else {
            menuToggle.setAttribute('aria-expanded', 'false');
            console.log('close')
        }
    });

    menuToggle.addEventListener('mouseenter', function () {
        menuToggleIconOff.style.opacity = '0';
        menuToggleIconOff.style.transition = 'opacity 500ms';

        menuToggleIconOn.style.opacity = '1';
        menuToggleIconOn.style.transition = 'opacity 300ms';
        menuToggleIconOn.classList.add('fa-beat-fade')
    });

    menuToggle.addEventListener('mouseleave', function () {
        menuToggleIconOn.style.opacity = '0';
        menuToggleIconOn.style.transition = 'opacity 300ms';
        menuToggleIconOn.classList.remove('fa-beat-fade')

        menuToggleIconOff.style.opacity = '1';
        menuToggleIconOff.style.transition = 'opacity 500ms';
    });

    closeMenu.addEventListener('click', function (e) {
        e.preventDefault();
        mobileMenu.style.transition = 'margin-left 300ms';
        mobileMenu.style.marginLeft = '100%';
        menuToggle.setAttribute('aria-expanded', 'false');
    });

    closeMenu.addEventListener('mouseenter', function () {
        closeMenuIconOff.style.opacity = '0';
        closeMenuIconOff.style.transition = 'opacity 500ms';

        closeMenuIconOn.style.opacity = '1';
        closeMenuIconOn.style.transition = 'opacity 300ms';
        closeMenuIconOn.classList.add('fa-beat-fade')
    });

    closeMenu.addEventListener('mouseleave', function () {
        closeMenuIconOn.style.opacity = '0';
        closeMenuIconOn.style.transition = 'opacity 500ms';
        closeMenuIconOn.classList.remove('fa-beat-fade')

        closeMenuIconOff.style.opacity = '1';
        closeMenuIconOff.style.transition = 'opacity 300ms';
    });

    telephoneIcon.addEventListener('mouseenter', function () {
        telephoneIcon.querySelector('i').classList.add('fa-shake')
    });

    telephoneIcon.addEventListener('mouseleave', function () {
        telephoneIcon.querySelector('i').classList.remove('fa-shake')
    });
});


const el = document.querySelector(".site-header-wrapper")
const observer = new IntersectionObserver(
    ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
    {threshold: [1]}
);

if (el) {
    observer.observe(el);
}
