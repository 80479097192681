import './damselfly-creative-console.js'
/*import Slick from 'slick-slider'
import 'slick-slider/slick/slick.css'
import 'slick-slider/slick/slick-theme.css'
import './slider.js'*/

import './navigation.js'
import './menu-mobile.js'
import './skip-link-focus-fix.js'

import AOS from 'aos'
import 'aos/dist/aos.css'

import './accordion.js'

document.addEventListener('DOMContentLoaded', function () {
    init();
})


function init() {
    // init AOS
    AOS.init({
        once: true,
        duration: 500,
        startEvent: 'load',
        //offset: -230
    });

    document.querySelectorAll('img')
        .forEach((img) =>
            img.addEventListener('load', () =>
                AOS.refresh()
            )
        );
}

