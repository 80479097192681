import {slideUp, slideDown} from 'es6-slide-up-down';

(() => {
    document.addEventListener('DOMContentLoaded', function () {
        const accordions = document.querySelectorAll('.dc__accordion');
        if (accordions.length > 0) {
            accordions.forEach(function (accordion) {
                const contents = accordion.querySelectorAll('.dc__content');
                contents.forEach(function (content) {
                    content.style.display = 'none';
                });

                const titles = accordion.querySelectorAll('a.dc__title');
                titles.forEach(function (title) {
                    title.addEventListener('click', function (e) {
                        e.preventDefault();
                        const container = e.target.closest('.dc__accordion');
                        const thisEntry = e.target.parentNode;
                        const thisContent = e.target.nextElementSibling;

                        // Close all other accordions
                        const entries = container.querySelectorAll('.dc__entry');
                        entries.forEach(function (entry) {
                            if (entry !== thisEntry) {
                                entry.classList.remove('active');
                                slideUp(entry.querySelector('.dc__content'))
                            }
                        });

                        // Open or close this accordion
                        if (thisEntry.classList.contains('active')) {
                            thisEntry.classList.remove('active');
                            slideUp(thisContent);

                        } else {
                            thisEntry.classList.add('active');
                            slideDown(thisContent);
                        }

                    });
                });
            });
        }
    });
})();
